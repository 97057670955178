<template>
  <interior-page-content-container
    nav-selection="projects"
    @back-click="backNav"
    :show-bottom-nav="true"
    :show-back-button-top-left="true"
    :show-back-button-in-nav="false"
    :modal-show="showWalkthroughModal"
    :loading="loading"
  >

      <template #modal>
        <walkthrough-modal
          v-if="showWalkthroughModal"
          title="KIV Projects!"
          @confirm="incrementWalkthrough"
          @cancel="cancelWalkthrough"
          description="
            You need to create a project by adding the
            name and postal code to start the decluttering,
            safety and downsizing work!
            Kept in View projects allow you to organize your downsizing endevours
            to be as effective as possible. They also allow you to ensure that
            safety is kept at the center of attention!
          "
          textCancel='Finish'
          textConfirm='Continue'
        />
      </template>

    <template #title>
      Projects
    </template>

    <template #content>

      <div class="flex flex-col items-center w-full min-h-full py-2">
        <div class="flex flex-row items-center mb-4">
          <search-bar
            :value.sync="search"
          />
        </div>

        <project-component
          v-for="(project, index) in filteredProjects"
          @click="navToProject(project.id)"
          :key="`project-${index}`"
          :name="project.name"
          :accessType="accessType(project)"
          :image="project.picture.url"
        />

        <p
          v-if="filteredProjects.length == 0 && projectsList.length > 0"
          class="text-content"
        >
          There are no projects that match your search query
        </p>

        <div
          v-if="projectsList.length == 0"
          class="`
            flex flex-col justify-end items-center flex-grow
            px-16 font-bold text-center text-content
          `"
        >
          <p>You currently do not have any projects.</p>
          <p>Click the button below to create your first!</p>
        </div>

        <div class="flex flex-col justify-end flex-grow flex-shrink-0 pt-4 pb-10">

          <button-large
            @click="$router.push({name: 'ProjectCreate'})"
            :class="{'animate-pulse border-2 rounded-lg border-yellow-300':pulseNewProject}"
            color="primary"
          >
            New Project
          </button-large>
        </div>

      </div>

    </template>

    <template #bottom-nav-center>
      <circle-plus
        @click="$router.push({name: 'ProjectCreate'})"
        class="cursor-pointer new-project"
        :class="{'animate-pulse border-2 rounded-lg border-yellow-300':pulseNewProject}"
        :size='37'
      />
    </template>

  </interior-page-content-container>
</template>

<script>
import CirclePlus from 'vue-material-design-icons/PlusCircle.vue';

import { mapProjectFields } from '@/store/mappers';
import { USER_COMPLETE_PAYMENT, USER_WALKTHROUGH_DISPATCH, PROJECTS_ALL } from '@/store/actions';
import { USER_PLAN, USER_WALKTHROUGH_STATE, PROJECT_PERMISSIONS } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import ProjectComponent from '@/components/projects/ProjectComponent.vue';
import SearchBar from '@/components/shared/inputs/SearchBar.vue';
import WalkthroughModal from '../../components/walkthrough/WalkthroughModal.vue';
import ButtonLarge from '../../components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'Projects',
  components: {
    InteriorPageContentContainer,
    CirclePlus,
    ProjectComponent,
    SearchBar,
    WalkthroughModal,
    ButtonLarge,
  },
  data() {
    return {
      search: '',
      loading: true,
    };
  },
  computed: {
    ...mapProjectFields(['projectsList']),
    filteredProjects() {
      const projectsList = this.projectsList.filter((project) => project.name.toLowerCase()
        .includes(
          this.search.toLowerCase(),
        ));
      return projectsList;
    },
    walkthroughData() {
      return {
        stage: this.$store.getters[USER_WALKTHROUGH_STATE],
      };
    },
    pulseNewProject() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 3;
    },
    showWalkthroughModal() {
      return this.$store.getters[USER_WALKTHROUGH_STATE] === 2;
    },
  },
  beforeRouteLeave(to, from, next) {
    const plan = this.$store.getters[USER_PLAN];
    if (this.walkthroughData.stage < 100) {
      if ((this.projectsList.length > 0 || plan !== null) && to.name === 'Dashboard') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 15);
      } else if (to.name === 'ProjectCreate') {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else if (this.$store.getters[USER_WALKTHROUGH_STATE] !== 0) {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
      }
    }
    next();
  },
  async created() {
    console.log(this.$store.getters[USER_WALKTHROUGH_STATE]);
    await this.$store.dispatch(PROJECTS_ALL)
      .finally(() => { this.loading = false; });
    if (this.$route.query.session_id) {
      this.$store.dispatch(USER_COMPLETE_PAYMENT, this.$route.query.session_id);
      this.$router.push({ name: 'Projects' });
    }
  },
  methods: {
    incrementWalkthrough() {
      const plan = this.$store.getters[USER_PLAN];
      if (this.projectsList.length === 0 && plan === null) {
        this.$store.dispatch(USER_WALKTHROUGH_DISPATCH);
      } else {
        this.$router.push('Dashboard');
      }
    },
    cancelWalkthrough() {
      this.$store.dispatch(USER_WALKTHROUGH_DISPATCH, 100);
    },
    backNav() {
      this.$router.push('Dashboard');
    },
    navToProject(projectId) {
      this.$router.push({
        name: 'Project',
        params: {
          project_id: projectId,
          tab: 'Declutter',
        },
      });
    },
    accessType(project) {
      const type = this.$store.getters[PROJECT_PERMISSIONS](project).split('');
      return type.splice(0, 1)[0].toUpperCase() + type.join('');
    },
  },
};
</script>

<style>

</style>
